import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IPaymentOrder } from '../models/interfaces';
import { WebsocketService, WsData, API_URL } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

    reservedCoinos: Array<any> = [];
    order: IPaymentOrder;
    orders: Array<IPaymentOrder> = [];
    transactionListener: BehaviorSubject<WsData>;

    constructor(public socket: WebsocketService) { 
        this.transactionListener = new BehaviorSubject(new WsData());
        this.socket.listener.subscribe(data => {
            switch (data.event) {
                case "order":
                case "buy":
                    this.handleBuyMessage(data)
                    break;
                case "cancel":
                    this.handleCancel(data)
                    break;
                case "reserve":
                    if (data.data) this.reservedCoinos = data.data;
                    break;
            }
        })
    }

    getTotalPrice(forCurrency = null) {
        let total = 0;
        this.reservedCoinos.forEach(c => {
            total += c.price
        })
        return total.toFixed(2);
    }

    continuePurchase(currency: string, wallet: string) {
        const data = new WsData();
            data.event = "buy";
            data.data = {
                currency,
                wallet
            }
            this.socket.sendMessage(data);
    }

    cancelOrder() {
        const data = new WsData();
        data.event = "cancel";
        data.data = {
            orderId: this.order.orderId
        }
        this.socket.sendMessage(data);
    }

    handleBuyMessage(data) {
        this.transactionListener.next(data)
        if (data.error) {
            return;
        }
        
        let currentOrder: IPaymentOrder = data.data;
        this.addOrder(currentOrder);
        
    }

    addOrder(currentOrder: IPaymentOrder) {
        if (!this.order && currentOrder.status == "waiting" || (this.order && this.order.orderId == currentOrder.orderId)) this.order = currentOrder

        let found = false;
        for (let i = 0; i < this.orders.length; i++) {
            if (this.orders[i].orderId == currentOrder.orderId) {
                this.orders[i] = currentOrder;
                found = true;
            }
        }
        if (!found) this.orders.push(currentOrder);
    }

    handleTransactionMessage(data) {
        if (data.error) {
            return;
        }
        this.order = data.data;
        this.transactionListener.next(data)
    }
    handleCancel(data) {
        if (data.error) {
            return;
        }
        
        if (this.order && this.order.orderId == data.data.orderId) {
            console.log("Redirecting message")
            this.order = null;
            this.orders = this.orders.filter(o => o.orderId != data.data.orderId)
            console.log(data, this.orders);
            this.transactionListener.next(data)
        }
    }

    getCurrency(currency: string) {
        const chain = this.chainUsed(currency);
        let returnCurrency = currency;
        if (chain) {
            console.log("Chain found")
            returnCurrency = returnCurrency.replace("TRC20", "");
            returnCurrency = returnCurrency.replace("BSC", "");
            console.log(returnCurrency)
        }
        switch (returnCurrency) {
            case "MATICMAINNET":
            case "MATICPOLYGON":
                returnCurrency = "MATIC";
                break;
            case "EXERNAL":
                returnCurrency = "MOONPAY";
                break;
            default:
                returnCurrency = returnCurrency;
                break;
        }
        if (chain) {
            returnCurrency = returnCurrency + " on " + chain
        }
        return returnCurrency;
    }

    chainUsed(currency: string): string {
        switch (true) {
            case currency.indexOf("TRC20") > -1:
                return "TRC20 - Tron blockchain";
            case currency.indexOf("BSC") > -1:
                return "BSC - Binance Smart Chain";
            default:
                return null;
        }
    }

    round(number: number) {
        
    }

    async sendInvoice(email, orderId): Promise<boolean> {
        try {
            const resp = await fetch(API_URL + "/updateEmail", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({email, orderId})
            })
            console.log(resp)
            return true;
        }
        catch(e) {
            console.log(e)
            throw(e);
        }
    }
}
