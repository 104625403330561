import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'image',
    loadChildren: () => import('./image/image.module').then( m => m.ImagePageModule)
  },
  {
    path: 'causes',
    loadChildren: () => import('./causes/causes.module').then( m => m.CausesPageModule)
  },
  {
    path: 'purchase',
    loadChildren: () => import('./pages/purchase/purchase.module').then( m => m.PurchasePageModule)
  },
  {
    path: 'order',
    loadChildren: () => import('./pages/order/order.module').then( m => m.OrderPageModule)
  },
  {
    path: 'wallet',
    loadChildren: () => import('./pages/wallet/wallet.module').then( m => m.WalletPageModule)
  },
  {
    path: 'coino',
    loadChildren: () => import('./pages/coino/coino.module').then( m => m.CoinoPageModule)
  },
  {
    path: 'coino/:id',
    loadChildren: () => import('./pages/coino/coino.module').then( m => m.CoinoPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
