import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {

    constructor(public modalCtrl: ModalController) { }

    ngOnInit() {}

    closeModal() {
        this.modalCtrl.dismiss();
    }
    
    scrollTo(event, anchor) {
        event.preventDefault();
        const elem = document.getElementById(anchor);
        if (elem) {
            elem.scrollIntoView({behavior: "smooth", inline: "nearest"})
        }
    }

}
