import { Injectable } from '@angular/core';
import { PurchaseService } from './purchase.service';
import { Web3Service } from './web3.service';
import { WebsocketService, API_URL } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class CoinoService {
    coinosInWallet: {[key: number] : any} = {};
    totalInWallet = 0;
    maticWallet: string = null;
    selectedCoino: any = null;
    fromHistory = false;
    loading = false;
    fetched = false;

    constructor(public purchaseSrv: 
        PurchaseService, 
        public websocketSrv: WebsocketService,
        public web3Srv: Web3Service) { }

    async getHistory(wallet: string = null): Promise<boolean> {
        
        if (!wallet) {
            try {
                wallet = await this.web3Srv.getMaticAccount()
            } 
            catch(e) {
                console.log(e)
                throw e;
            }
        }

        //console.log("Matic wallet " + wallet)

        if (!this.web3Srv.account && !wallet) {
            throw new Error("No account available")
        }

        this.loading = true;
        this.purchaseSrv.orders = [];
        this.purchaseSrv.order = null;
        this.totalInWallet = 0;
        this.coinosInWallet = {};

        //console.log("Fetching history")
        const resp = await fetch(API_URL + "/history/" + wallet)
        //console.log(resp)
        if (resp.ok) {
            let json = await resp.json();
            //console.log(json);
            for (let t of json.transactions) {
                this.purchaseSrv.addOrder(t);
            }

            //console.log(json.wallet);
            for (let c of json.wallet) {
                this.coinosInWallet[c.number] = c;
            }
            this.totalInWallet = Object.keys(this.coinosInWallet).length;
        }
        this.loading = false;
        this.fetched = true;
        return true;
    }

    async checkReservation(): Promise<Array<any>> {
        const resp = await fetch(API_URL + "/reserved/" + this.websocketSrv.clientId)
        return resp.json();
    }

    async getCoinoWithId(id): Promise<any> {
        const resp = await fetch(API_URL + "/coino/" + id)
        return resp.json();
    }
}
