/* eslint-disable @typescript-eslint/quotes */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { io } from "socket.io-client";
import { environment } from 'src/environments/environment';

//export const API_URL = "http://localhost:3000"
export const API_URL = environment.api_url;
const socket = io(API_URL);

export class WsData {
    event: string;
    data: any;
    error: string;
}

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
    coinoPrice = 0;
    reserved = 0;
    free = 100000;
    listener: BehaviorSubject<WsData>;
    clientId: string;
    buyActive = true;
    contractAddress = null;

    constructor() {
        this.listener = new BehaviorSubject(new WsData());

        socket.on("connect", () => {
            this.clientId = socket.id;
            console.log("Connected to websocket server"); // true
        });

        socket.on("disconnect", () => {
            console.log("Disconnected from websocket server"); // true
        });

        socket.on("message", (data) => {
            //console.log(data);
            this.handleMessage(data);
        });
    }

    sendMessage(wsData: WsData) {
        //console.log("Sending data", wsData)
        socket.emit(wsData.event, wsData.data);
    }

    handleMessage(data: any) {
        try {
            const ev: string = data.event;
            if (ev) {
                const wsData = new WsData();
                wsData.event = ev;
                wsData.data = data.data;
                wsData.error = data.error;
                if (data.event) this.listener.next(wsData);

                switch (ev) {
                    case "price": {
                        this.coinoPrice = data.data.price;
                        this.reserved = data.data.reserved;
                        this.free = data.data.free;
                        this.buyActive = data.data.buyActive;
                        if (data.data.contract_address) {
                            this.contractAddress = data.data.contract_address;
                        }
                        console.log("Set price and reserved amout")
                        break;
                    }
                    case "reserve": {
                        console.log("Reserve message received")
                        break;
                    }
                    case "buy": {
                        console.log("Reserve message received")
                        break;
                    }
                    case "message": {
                        console.log("Wallet message received")
                        alert(data.data.message)
                        break;
                    }
                }
            }
        }
        catch(e) {
            console.log("Error handling websocket message", e);
        }
    }
}
