import { Component } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { HelpComponent } from './components/help/help.component';
import { CoinoService } from './services/coino.service';
import { Web3Service } from './services/web3.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
    public appPages = [
        { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
        { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
        { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
        { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
        { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
        { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
    ];
    public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
    constructor(public modalCtrl: ModalController, 
        public coinoSrv: CoinoService, 
        public nav: NavController,
        public web3Service: Web3Service) {
        }
    
    async openHelp() {
        let modal = await this.modalCtrl.create({component: HelpComponent})
        modal.present();
    }

    search(event) {
        //console.log(event);
        this.coinoSrv.getHistory(event.target.value);
        this.nav.navigateForward("wallet");
        setTimeout(() => {
            event.target.value = null;
        }, 500)
        
    }

    async connect() {
        let connect = await this.web3Service.connect();
        if (connect == true) {
            this.nav.navigateForward("wallet");
        }
    }

    loadWallet() {
        this.nav.navigateForward("wallet");
    }
}
