import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';
import { CoinbaseWalletProvider, CoinbaseWalletSDK } from '@coinbase/wallet-sdk';
import { LoadingController, ToastController } from '@ionic/angular';
import { async } from '@angular/core/testing';
import { WebsocketService, WsData } from './websocket.service';
global.Buffer = Buffer;

//import Web3Modal from "web3modal";

declare let Web3Modal: any;
declare let Web3: any;
declare let WalletConnectProvider: any ;
declare let Fortmatic: any ;
const CHAIN_ID_TESTNET = 80001;
const CHAIN_ID_MAINNET = 137;

const NETWORK_URL = "https://polygon-mainnet.infura.io/v3/118d22496fc64626aee9b83030ebee38"
const TEST_NETWORL_URL = "https://polygon-mumbai.infura.io/v3/118d22496fc64626aee9b83030ebee38"

const balanceOfABI = [
    {
        "constant": true,
        "inputs": [
            {
                "name": "_owner",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "name": "balance",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
];
const tokenContract = "0x31F44eeC23f45C042F94Ad7e10cC344862AF73E6"


const providerOptions = {
    walletconnect: {
        package: WalletConnectProvider.default,
        options: {
            appName: "Coino", // Required
            infuraId: "118d22496fc64626aee9b83030ebee38",
            rpc: {
                137: "https://polygon-rpc.com"
            },
            chainId: 137
        }
    },
    'custom-coinbase': {
        display: {
          name: 'Coinbase',
          description: 'Scan with Coinbase to connect',
          logo: "https://media-exp1.licdn.com/dms/image/C560BAQHsPlWyC0Ksxg/company-logo_200_200/0/1620063222443?e=2147483647&v=beta&t=ygbKsetR5vq5u0YLLupNKPeKre8iq_CcefcAtSzNJYs"
        },
        options: {
            appName: 'Coino', // Your app name
            //infuraId: "118d22496fc64626aee9b83030ebee38",
            networkUrl: `https://polygon-rpc.com`,
            chainId: 137,
        },
        package: CoinbaseWalletSDK,
        connector: async (_, options) => {
            const { appName, networkUrl, chainId } = options
            const walletLink = new CoinbaseWalletSDK({
                appName
            });
            const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
            await provider.enable();
            return provider;
        }
      }
}
 
 
 // Chosen wallet provider given by the dialog window
 let provider;
 
 
 // Address of the selected account
 let selectedAccount;

@Injectable({
  providedIn: 'root'
})
export class Web3Service {

    amount = 0;
    account = null;
    maticChainSet = false;
    web3Modal = null;
    web3 = null;
    get hasEth() {
        const hasEthereum = (window.ethereum) ? true : false;
        return hasEthereum;
    }

    constructor(
        public loadingCtrl: LoadingController,
        public toastCtrl: ToastController,
        public websocket: WebsocketService
    ) { 
        this.web3Modal = new Web3Modal.default({
            cacheProvider: true, // optional
            providerOptions
        });
    }

    async connect(wallet: "walletconnect"|"coinbasewallet" = null): Promise<boolean> {
        
        const cachedProviderName = JSON.parse(localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER"));
        let signRequest = false;
        if (cachedProviderName) {
            console.log("There is already a cached provider", cachedProviderName)
            signRequest = true;
        }
        let provider;
        //console.log(wallet);
        try {
            if (wallet) {
                let connectTo: string = wallet;
                if (connectTo == "coinbasewallet") connectTo = "custom-coinbase"
                provider = await this.web3Modal.connectTo(connectTo);
            } else {
                provider = await this.web3Modal.connect();
            }
        }
        catch(e) {
            console.log(e);
            if (provider || cachedProviderName) this.disconnect();
            return false;
        }

        this.web3 = new Web3(provider);
        provider.on("disconnect", error => {
            this.disconnect(false);
        })
        
        if (signRequest == true && !this.account) {
            const signed = await this.signLogin()
            if (!signed) {
                console.log("Not signed")
                this.disconnect();
                return;
            }
        }
        
        const accounts = await this.web3.eth.getAccounts();
        //console.log(accounts);
        this.account = accounts[0] || null;
        if (!this.account) {
            this.disconnect();
            return false;
        }
        this.linkToWallet();
        return true
    }

    timeout(ms): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async disconnect(withMessage = true) {
        await this.web3Modal.clearCachedProvider();
        window.localStorage.clear();
        this.account = null;

        const name = JSON.parse(localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER"));
        if (this.web3 && this.web3.currentProvider instanceof CoinbaseWalletProvider) {
            console.log("Disconnect coinbase")
            this.web3.currentProvider.close()
        } else if (this.web3 && this.web3.currentProvider && this.web3.currentProvider.connector) {
            this.web3.currentProvider.connector.killSession();
        }
        this.web3 = null;
    }

    signLogin = () => {
        return new Promise(async (resolve) => {
            let toast;
            if (!window.ethereum) {
                toast = await this.toastCtrl.create({
                    position: "middle",
                    message: "Accept the login request from your wallet app",
                    color: "dark",
                    buttons: [{
                        text: "cancel",
                        role: "cancel",
                        handler: () => {
                            resolve(false)
                        }
                    }]
        
                })
                await toast.present();
            }
    
            var message = "Login to coiño with this wallet?"
            var hash = this.web3.utils.sha3(message)
            try {
                var accounts = await this.web3.eth.getAccounts()
                var signature = await this.web3.eth.personal.sign(message, accounts[0])
                if (toast) toast.dismiss();
                if (signature) {
                    resolve(true);
                    return;
                }
                resolve(false)
            }
            catch(e) {
                if (toast) toast.dismiss();
                resolve(false);
            }
        })
    }

    getNetworkId = async () => {
        const currentChainId = await this.web3.eth.net.getId()
        return currentChainId
    }

    swichNetwork = async (chainId = 137) => {
        if (this.maticChainSet == true && this.account) return true;

        const currentChainId = await this.getNetworkId()

        if (currentChainId !== chainId && (currentChainId != 1 && chainId != 137)) {
            let toast = null;
            if (!window.ethereum) {
                toast = await this.toastCtrl.create({
                    message: "Accept the network switch from your wallet app",
                    position: "middle",
                    color: "danger",
                    buttons: ["Ok"]
                })
                await toast.present();
            }
            try {
                //console.log("Switching chains");
                const chain = await this.web3.currentProvider.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ 
                        chainId: Web3.utils.toHex(chainId),
                    }],
                })
            }
            catch(e) {
                console.log("Error from chain switch")
                console.log(e)
            }

            const newChain = await this.getNetworkId()
            //console.log("Logged into chain " + newChain);
            if (toast) await toast.dismiss()

            if (newChain != chainId) {
                alert("You must set your chain to Polygon")
                this.account = null;
                return;
            }
            const accounts = await this.web3.eth.getAccounts();
            this.account = accounts[0] || null;
            if (this.account) this.maticChainSet = true;
        } else {
            const accounts = await this.web3.eth.getAccounts();
            this.account = accounts[0] || null;
            if (this.account) this.maticChainSet = true;
        }
    }

    async getMaticAccount(): Promise<string> {
        //if (this.account) return this.account;
        try {
            await this.swichNetwork();
            if (!this.account) throw new Error("No matic address available")
            return this.account;
        }
        catch(e) {
            throw e
        }
    }

    async linkToWallet() {
        const wsData = new WsData();
        wsData.event = "link";
        wsData.data = {
            wallet: this.account
        }
        this.websocket.sendMessage(wsData)
    }

    async addCoinoToken() {
        const tokenAddress = '0x31F44eeC23f45C042F94Ad7e10cC344862AF73E6';
        const tokenSymbol = 'COINO';
        const tokenDecimals = 0;
        const tokenImage = 'https://coino.art/assests/icon/favicon-32x32.png';

        try {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        const wasAdded = await this.web3.currentProvider.request({
            method: 'wallet_watchAsset',
            params: {
            options: {
                address: tokenAddress, // The address that the token is at.
                symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                decimals: tokenDecimals, // The number of decimals in the token
                image: tokenImage, // A string url of the token logo
            },
            },
        });

        if (wasAdded) {
            console.log('Thanks for your interest!');
        } else {
            console.log('Your loss!');
        }
        } catch (error) {
           console.log(error);
        }
    }
}
